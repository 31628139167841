import styled from 'styled-components';
import { OldPriceWrapper } from 'src/comps/Atom/PriceParts/OldPrice/styles';
import { CurrentPriceWrapper } from 'src/comps/Atom/PriceParts/CurrentPrice/styles';

export const PropertyPricesBase = styled.div`
  text-align: right;
  width: auto;
`;

export const PricesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  flex-wrap: wrap;

  & > ${OldPriceWrapper}, & > ${CurrentPriceWrapper} {
    color: ${({ theme }) => theme.colors.grey3};
    font-weight: 500;
    font-size: 16px;
    line-height: 14px;
  }
`;

export const VATIncluded = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  margin-top: 1px;
  color: ${({ theme }) => theme.colors.grey3};
  white-space: nowrap;
`;
