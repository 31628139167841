import React, { FC } from 'react';

import { NotAvailable } from 'src/comps/Atom/PriceParts/NotAvailable';
import { TooLatePrice } from 'src/comps/Atom/PriceParts/TooLate';
import { usePrices } from 'src/hooks/usePrices';
import { Room } from 'src/types/search';
import { PriceType } from 'src/types/prices';
import { usePaxes } from 'src/hooks/usePaxes';

import NetPerNight, { IPriceNet } from 'src/comps/Molecules/PriceTypes/NetPerNight';
import Gross, { IPriceGross } from 'src/comps/Molecules/PriceTypes/Gross';
import AllIn, { IPriceAllIn } from 'src/comps/Molecules/PriceTypes/AllIn';
import AllInDaily, { IPriceAllInDaily } from 'src/comps/Molecules/PriceTypes/AllInDaily';
import NetPerNightAndTotal, {
  IPriceNetAndTotal,
} from 'src/comps/Molecules/PriceTypes/NetPerNightAndTotal';

type PropertyPricesProps =
  | IPriceNet
  | IPriceGross
  | IPriceAllIn
  | IPriceAllInDaily
  | IPriceNetAndTotal;

type SpecificPropertyPricesProps = {
  [R in PropertyPricesProps['priceType']]: FC<Extract<PropertyPricesProps, { priceType: R }>>;
};

const Prices: SpecificPropertyPricesProps = {
  [PriceType.Net]: NetPerNight,
  [PriceType.Gross]: Gross,
  [PriceType.AllIn]: AllIn,
  [PriceType.AllInDaily]: AllInDaily,
  [PriceType.TrivagoNet]: NetPerNightAndTotal,
};

const PropertyPrices = (props: PropertyPricesProps & { rooms: Room[] }) => {
  const { rooms, price } = props;
  const paxes = usePaxes();
  const { priceType } = usePrices({ rooms: paxes });

  if (!rooms || rooms.length === 0) return <NotAvailable />;

  if (!price) return <TooLatePrice />;

  const PriceComponent = Prices[priceType] as FC<PropertyPricesProps>;
  return <PriceComponent {...props} />;
};

export default PropertyPrices;
