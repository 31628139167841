import React from "react";
import Price from "client/components/Price";
import { OldPriceWrapper } from "./styles";

interface IOldPrice {
  readonly amount: number;
  readonly currency: string;
}

export function OldPrice({ amount, currency }: IOldPrice) {
  return (
    <OldPriceWrapper data-testid="price-old">
      <Price amount={amount} currency={currency} />
    </OldPriceWrapper>
  );
}
