import Price from "client/components/Price";
import React from "react";
import { useIntl } from "react-intl";
import { PricePerNightWrapper } from "./styles";

interface IPricePerNight {
  readonly amount: number;
  readonly currency: string;
  readonly source: string;
  readonly showNet?: boolean;
}

export function PricePerNight({ amount, currency, source, showNet = false }: IPricePerNight) {
  const intl = useIntl();
  return (
    <PricePerNightWrapper source={source} data-testid="price-per-night" showNet={showNet}>
      <Price amount={Math.round(amount)} currency={currency} />
      {` / ${intl.formatMessage({ id: 'global.night' })}`}
    </PricePerNightWrapper>
  )
}
