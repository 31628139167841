import styled from "styled-components";

function getSizeMultiplier(priceLength: number) {
  if (priceLength > 10) {
    return 0.5;
  }

  if (priceLength > 8) {
    return 0.6;
  }

  if (priceLength > 6) {
    return 0.8;
  }

  return 1;
}

export const CurrentPriceWrapper = styled.span<{ priceLength: number, prime: boolean }>`
  display: block;
  font-weight: 700;
  font-size: ${props => getSizeMultiplier(props.priceLength) * 21}px;
  color: ${props => (props.prime ? props.theme.colors.prime1 : props.theme.colors.grey2)};  
  line-height: 1.3;
`;
