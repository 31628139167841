import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import IconFacility from 'client/icons/facilities/Facility';
import messages from './messages';
import { FacilityBase } from './styles';

const Facility = ({ perk, color = 'green4', size = 'm', withIcon = true }) => {
  const message = messages[perk.name];

  return (
    <FacilityBase color={color} size={size} data-testid="facility">
      {withIcon && <IconFacility name={perk.icon} />}
      {message ? <FormattedMessage {...message} tagName="p" /> : <p>{perk.name}</p>}
    </FacilityBase>
  );
};

Facility.propTypes = {
  color: PropTypes.string,
  perk: PropTypes.shape({ icon: PropTypes.string, name: PropTypes.string }).isRequired,
  size: PropTypes.oneOf(['xs', 's', 'm']),
  withIcon: PropTypes.bool,
};

export default Facility;
