import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import Score from 'client/components/Score';
import { getScoreRange } from 'client/components/Score/utils';
import messages from 'client/components/RatingContent/messages';

import { Messages } from 'src/types/messages';

import { Comments, Category, ScoreReviewsBase } from './styles';

interface Props {
  score: number;
  comments: number;
  handleClick?: () => void;
  className?: string;
}

const ScoreReviews = ({ className = '', score, comments, handleClick }: Props) => {
  const { locale } = useIntl();
  if (!score || !comments || comments === 0) return null;
  const category = getScoreRange(score);
  const numberFormatter = new Intl.NumberFormat(locale, { useGrouping: true });

  const ratingMessages: Messages = messages;

  return (
    <ScoreReviewsBase
      className={className}
      onClick={handleClick}
      toScroll={!!handleClick}
      data-testid="reviews-container"
    >
      <Score value={score} size="small" />
      <Category color={category.color}>
        <FormattedMessage {...ratingMessages[`score${category.level}`]} />
      </Category>
      <Comments size="s" color="grey3" fontFamily="primary">
        {numberFormatter.format(comments)} <FormattedMessage {...messages.reviews} />
      </Comments>
    </ScoreReviewsBase>
  );
};

export default ScoreReviews;
