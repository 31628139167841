import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { calculateDiscount } from 'client/components/Discount/utils';
import DiscountTagLoader from 'client/components/DiscountTagLoader';
import IconLightning from 'client/icons/Lightning';
import { useBusiness } from 'src/hooks/useBusiness';

import { DiscountTagBase, PrimeDiscountText } from './styles';

interface Props {
  isLoading?: boolean;
  price: number;
  priceBefore: number;
  prime?: boolean;
  className?: string;
}

const DiscountTag = ({
  isLoading = false,
  price,
  priceBefore,
  prime = false,
  className = 'discount-tag',
}: Props) => {
  const { promo } = useBusiness();
  const discount = calculateDiscount(price, priceBefore);

  const primeDiscount = useMemo(
    () => (
      <PrimeDiscountText>
        <IconLightning size={20} />
        <FormattedMessage id="global.prime" />
        <FormattedMessage id="discount.today" values={{ discount: `: -${discount}%` }} />
      </PrimeDiscountText>
    ),
    [discount]
  );

  const discountCopy = useMemo(() => {
    if (promo) {
      return (
        <>
          <FormattedMessage id={promo.global.tags.textId} />
          {discount && (
            <FormattedMessage id="discount.today" values={{ discount: `: -${discount}%` }} />
          )}
        </>
      );
    }
    return (
      <>
        {`-${discount}% `}
        <FormattedMessage id="global.today" />
      </>
    );
  }, [discount, promo]);

  if (!promo && isLoading) {
    return <DiscountTagLoader />;
  }

  if (!promo && !discount) {
    return null;
  }

  return (
    <DiscountTagBase className={className} promo={promo} prime={prime}>
      {prime ? primeDiscount : discountCopy}
    </DiscountTagBase>
  );
};

export default DiscountTag;
