import React, { FC } from "react";

import { FormattedMessage } from "react-intl";
import messages from 'src/comps/Property/messages';
import { OldPrice } from "src/comps/Atom/PriceParts/OldPrice";
import { CurrentPrice } from "src/comps/Atom/PriceParts/CurrentPrice";
import { PropertyPricesBase, SubPrice } from "./styles";
import { IPriceBase, PriceType } from "src/types/prices";

export interface IPriceGross extends IPriceBase {
  priceType: PriceType.Gross;
  nights: number;
}

const Gross: FC<IPriceGross> = ({ priceOld, price, currency, prime, nights }) => {
  return (
    <PropertyPricesBase>
      {priceOld > price && (
        <OldPrice amount={priceOld} currency={currency} />
      )}
      <CurrentPrice amount={price} currency={currency} isPrime={prime} />
      <SubPrice data-testid="price-nights">
        <FormattedMessage {...messages.nights} values={{ nights }} />
      </SubPrice>
    </PropertyPricesBase>
  );
}

export default Gross;
