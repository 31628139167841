import React, { FC } from "react";
import { useIntl } from "react-intl";

import { PricesWrapper, PropertyPricesBase, VATIncluded } from "./styles";
import { PricePerNight } from "src/comps/Atom/PriceParts/PricePerNight";
import { OldPrice } from "src/comps/Atom/PriceParts/OldPrice";
import { CurrentPrice } from "src/comps/Atom/PriceParts/CurrentPrice";
import { IPriceBase, PriceType } from "src/types/prices";

export interface IPriceNet extends IPriceBase {
  // eslint-disable-next-line react/no-unused-prop-types
  priceType: PriceType.Net;
  pricePerNightSubtotal: number;
  source: string;
}

const NetPerNight: FC<IPriceNet> = ({ source, pricePerNightSubtotal, currency, priceOld, price, prime }) => {
  const intl = useIntl();
  return (
    <PropertyPricesBase>
      <PricePerNight amount={Math.round(pricePerNightSubtotal)} currency={currency} source={source} />

      <PricesWrapper>
        {priceOld > price && (
          <OldPrice amount={priceOld} currency={currency} />
        )}

        <CurrentPrice amount={price} currency={currency} isPrime={prime} />
        <VATIncluded data-testid="vat-included">{intl.formatMessage({ id: 'global.vat-included' })}</VATIncluded>
      </PricesWrapper>
    </PropertyPricesBase>
  );
}

export default NetPerNight;
