import styled from "styled-components";
import { colors } from "client/styles";

export const TooLate = styled.div`
  color: ${colors.red2};
  font-size: 14px;
  font-weight: 700;
  width: 100%;

  svg {
    vertical-align: top;
    fill: ${colors.red2};
    margin-right: 8px;
    font-weight: 700;
  }
`;

export const SeeOtherDates = styled.div`
  border-radius: 8px;
  border: 2px solid ${colors.grey1};
  color: ${colors.grey1};
  font-size: 14px;
  font-weight: 700;
  margin-top: 4px;
  padding: 5px;
  text-align: center;
`;


