import React, { FC } from 'react';
import { useIntl } from 'react-intl';

import { PricesWrapper, PropertyPricesBase, VATIncluded } from './styles';
import { PricePerNight } from 'src/comps/Atom/PriceParts/PricePerNight';
import { OldPrice } from 'src/comps/Atom/PriceParts/OldPrice';
import { CurrentPrice } from 'src/comps/Atom/PriceParts/CurrentPrice';
import { IPriceBase, PriceType } from 'src/types/prices';

export interface IPriceNetAndTotal extends IPriceBase {
  // eslint-disable-next-line react/no-unused-prop-types
  priceType: PriceType.TrivagoNet;
  pricePerNightSubtotal: number;
  priceSubtotal: number;
  source: string;
}

const NetPerNightAndTotal: FC<IPriceNetAndTotal> = ({
  source,
  pricePerNightSubtotal,
  currency,
  priceOld,
  price,
  priceSubtotal,
  prime,
}) => {
  const intl = useIntl();
  return (
    <PropertyPricesBase>
      <PricePerNight
        amount={Math.round(pricePerNightSubtotal)}
        currency={currency}
        source={source}
      />

      <PricesWrapper>
        {priceOld > price && <OldPrice amount={priceOld} currency={currency} />}

        <CurrentPrice amount={priceSubtotal} currency={currency} isPrime={prime} />
        <VATIncluded data-testid="vat-not-included">
          {intl.formatMessage({ id: 'global.taxes-fees-not-inc' })}
        </VATIncluded>
      </PricesWrapper>
    </PropertyPricesBase>
  );
};

export default NetPerNightAndTotal;
