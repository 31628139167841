import React, { FC } from "react";
import { useIntl } from "react-intl";
import { PricesWrapper, PropertyPricesBase, VATIncluded } from "./styles";
import { OldPrice } from "src/comps/Atom/PriceParts/OldPrice";
import { IPriceBase, PriceType } from "src/types/prices";
import { PricePerNight } from "src/comps/Atom/PriceParts/PricePerNight";
import { CurrentPrice } from "src/comps/Atom/PriceParts/CurrentPrice";

export interface IPriceAllInDaily extends IPriceBase {
  // eslint-disable-next-line react/no-unused-prop-types
  priceType: PriceType.AllInDaily;
  pricePerNightWithFees: number;
  priceWithFees: number;
  source: string;
}

const AllInDaily: FC<IPriceAllInDaily> = ({ source, pricePerNightWithFees, currency, priceOld, price, prime, priceWithFees }) => {
  const intl = useIntl();
  return (
    <PropertyPricesBase>
      <PricePerNight amount={Math.round(pricePerNightWithFees)} currency={currency} source={source} />

      <PricesWrapper>
        {priceOld > price && (
          <OldPrice amount={priceOld} currency={currency} />
        )}

        <CurrentPrice amount={priceWithFees} currency={currency} isPrime={prime} />
        <VATIncluded data-testid="vat-included">{intl.formatMessage({ id: "net.price-total-taxes-included" })}</VATIncluded>
      </PricesWrapper>
    </PropertyPricesBase>
  );
}

export default AllInDaily;
