import { updatedMedia } from "client/styles";
import styled from "styled-components";

export const PricePerNightWrapper = styled.div<{ source?: string, showNet?: boolean }>`
  font-size: 21px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.grey2};
  line-height: 130%;
  text-align: left;
  margin-bottom: 6px;
  ${({ source }) => source === 'related' && 'margin-top: 16px;'}
  ${updatedMedia.onlyTablet} {
    ${({ showNet }) => showNet && 'text-align: left'};
  }
  ${updatedMedia.onlyMobile} {
    margin-top: 16px;
  }
`;
