import { useSelector } from 'react-redux';
import { getSearch } from "src/effects/Search/selectors";

export const usePaxes = () => {
  const { adults: reduxAdults, children: reduxChildren } = useSelector(getSearch);

  const reduxPaxes = reduxAdults.map((adults: number, index: number) => ({
    adults,
    children: reduxChildren[index],
  }));

  return reduxPaxes;
}
