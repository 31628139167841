/* eslint-disable no-nested-ternary */
import React, { useCallback, useRef, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import ContentLoader from 'react-content-loader';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';

import { getLinkPropsFromUrl } from 'lib/utils/page';
import { clickProperty } from 'client/contexts/Tracking/events';
import { getPerks } from './utils';
import { sendTrackingRequestAction } from 'client/contexts/Tracking/actions';

import CarouselPhotos from 'src/comps/CarouselPhotos';
import DiscountTag from 'client/components/DiscountTag';
import Facility from 'client/components/Facility';
import IconTrash from 'client/icons/Trash';
import Photo from 'client/components/Photo';
import PropertyLocation from './PropertyLocation';
import PropertyPrices from 'client/components/PropertyPrices';
import Stars from 'client/components/Stars';
import ScoreReviews from 'src/comps/ScoreReviews';

import { colors } from 'client/styles';
import messages from './messages';

import {
  BottomPlaceholders,
  ButtonRemove,
  Facilities,
  FacilitiesPlaceholder,
  FacilityContainer,
  Info,
  Location,
  Name,
  NameRating,
  NameScore,
  PricePlaceholder,
  PriceWrapper,
  PropertyBase,
  PropertyHeader,
  SoldOut,
  MediaContainer,
  PropertyWrapper,
  HighlightedText,
} from './styles';

import MobileOnlyPrice from '../MobileOnlyPrice';
import { useDevice } from 'src/hooks/useDevice';
import { useMarket } from 'src/hooks/useMarket';
import { useBusiness } from 'src/hooks/useBusiness';
import { BreakpointSizes } from 'src/types/device';
import useBus from 'src/hooks/useBus';
import { AFFILIATE_LIST, AFFILIATE_NAMES } from 'src/constants/affiliates';
import { usePrices } from 'src/hooks/usePrices';
import { PriceType } from 'src/types/prices';
import { getResponsiveImgProps } from 'src/comps/Gallery/utils';
import { DEFAULT_SOURCE } from 'src/constants/sources';

const imageSizes = {
  mobile: {
    size: '400px',
    srcWidth: 400,
  },
};

const FacilitiesList = React.memo(({ perks }) => (
  <ul data-testid="facilities">
    {perks.map(perk => (
      <FacilityContainer key={perk.icon}>
        <Facility perk={perk} size="s" color="grey2" />
      </FacilityContainer>
    ))}
  </ul>
));

FacilitiesList.propTypes = {
  perks: PropTypes.arrayOf(String),
};

const Property = ({
  address,
  board,
  boardCode,
  cancellation,
  city,
  citySlug,
  comments,
  country,
  currency,
  distance = 0,
  facilities,
  handleSwipeEvent,
  highlighted = false,
  id,
  isInCenter,
  isLoadingContent = false,
  name,
  nights = 0,
  neighborhood,
  parentAction,
  photos,
  position = 1,
  price,
  priceOld,
  priceWithFees,
  pricePerNightWithFees,
  pricePerNightSubtotal,
  isPrime,
  isMobile,
  stars,
  rooms,
  score = 0,
  slug,
  source = 'results',
  subtotal: priceSubtotal,
  url,
  wide,
  horizontal = false,
}) => {
  const router = useRouter();
  const intl = useIntl();

  const [firstRoom] = rooms ?? [];

  const { device, activeBreakpoint } = useDevice();
  const { market, language } = useMarket();
  const { promo, affiliate } = useBusiness();

  const dispatch = useDispatch();

  const [isHover, setIsHover] = useState(false);
  const ref = useRef(null);

  const { subscribe } = useBus();

  subscribe('map:marker:hover-in', value => {
    const isHovered = parseInt(value.markerId, 10) === id;
    setIsHover(isHovered);
    if (isHovered && ref?.current?.scrollIntoView && horizontal) {
      setTimeout(() => {
        ref.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }, 100);
    }
  });

  subscribe('map:marker:hover-out', () => {
    setIsHover(false);
  });

  const perks = getPerks(board, boardCode, cancellation, facilities);
  const isStatic = source === 'visited' || source === 'recommended' || source === 'hotelsSeen';
  const showLocation = source === 'results' || source === 'visited';

  const { priceType } = usePrices({ rooms: [board] });
  const showNightNetPrice = priceType === PriceType.Net || PriceType.TrivagoNet;

  const photosWithProps = useMemo(
    () =>
      photos.map(photo => ({
        ...getResponsiveImgProps(photo, imageSizes),
      })),
    [photos]
  );

  const photoCallback = useCallback(
    ({ photo }) => (
      <Photo
        deSaturated={!isLoadingContent && !price && !isStatic}
        lazyLoad
        name={name}
        photo={photo.src}
        width="100%"
        isResponsive={wide && activeBreakpoint >= BreakpointSizes.M}
        aspectRatio={horizontal ? '16/10' : null}
        srcSet={photo.srcSet}
        sizes={photo.sizes}
      />
    ),
    [wide, isLoadingContent, price, isStatic, name, activeBreakpoint, horizontal]
  );

  function buildLinkProps() {
    // TODO we should remove the way of obtaining this parameters
    if (url) {
      return getLinkPropsFromUrl(url);
    }

    return {
      country,
      slug,
      city: citySlug,
      search: {
        clickin: 'search',
        lang: language,
        content: 'list',
      },
    };
  }

  const { country: propertyCountry, slug: propertySlug, city: propertyCity } = buildLinkProps();

  // eslint-disable-next-line camelcase
  const {
    query: { aid, subtotal, taxes, total, offer_type: offerType, source: sourceParam },
  } = router;

  const href = {
    pathname: '/hotel/[country]/[slug]',
    query: {
      market,
      lang: language,
      country: propertyCountry,
      slug: `${propertySlug}_${propertyCity}`,
      ...(highlighted &&
        !!aid && {
          aid,
          ...(sourceParam && { source: sourceParam }),
          subtotal,
          taxes,
          total,
          offer_type: offerType,
          internal_navigation: true,
        }),
    },
  };

  function removeItem(event) {
    event.preventDefault();
    event.stopPropagation();
    parentAction({ propertyId: id });
  }

  const neighborhoodLocation = `${neighborhood}, ${city}`;
  let location = showLocation ? city : `${city}, ${address} `.trim();
  location = neighborhood ? neighborhoodLocation : location;

  const locationInfo = (
    <PropertyLocation
      location={location}
      distance={distance}
      isInCenter={isInCenter}
      showLocation={showLocation}
    />
  );

  function handleClick(event) {
    dispatch(
      sendTrackingRequestAction({
        event: clickProperty(source, {
          id,
          position,
          firstRoom,
        }),
      })
    );

    if (source === 'related' || isStatic) {
      event.preventDefault();
      router.push(href).then(() => window.scrollTo(0, 0));
    }
  }

  function handleDoubleClick(event) {
    if (source === 'map') {
      event.stopPropagation();
    }
  }

  return (
    <Link href={href} passHref>
      <PropertyWrapper
        data-tracking-item-position={position}
        data-testid={source || 'property'}
        target={device === 'desktop' ? '_blank' : '_self'}
        wide={wide}
        showNightNetPrice={showNightNetPrice}
        isHover={isHover}
        ref={ref}
        onClick={handleClick}
        onDoubleClick={handleDoubleClick}
        id={id}
        highlighted={highlighted}
        highlightedAid={AFFILIATE_LIST.includes(aid) && sourceParam !== DEFAULT_SOURCE}
      >
        {AFFILIATE_LIST.includes(aid) && highlighted && sourceParam !== DEFAULT_SOURCE && (
          <HighlightedText color="grey8" data-testid="highlighted-aid">
            &#128071; {intl.formatMessage({ id: 'global.found-on' })}
            &nbsp;<span>{AFFILIATE_NAMES[affiliate]}</span>
          </HighlightedText>
        )}
        <PropertyBase wide={wide} showNightNetPrice={showNightNetPrice}>
          <MediaContainer horizontal={horizontal}>
            {(isLoadingContent || promo || price || source === 'visited') && (
              <DiscountTag
                isLoading={isLoadingContent}
                price={price}
                priceBefore={priceOld}
                prime={isPrime}
              />
            )}

            {!price &&
              source !== 'recommended' &&
              source !== 'visited' &&
              source !== 'hotelsSeen' && (
                <SoldOut data-testid="sold-out">
                  <FormattedMessage {...messages.soldOut} />
                </SoldOut>
              )}

            <CarouselPhotos photos={photosWithProps} handleSwipeEvent={handleSwipeEvent}>
              {photoCallback}
            </CarouselPhotos>

            {(source === 'visited' || source === 'hotelsSeen') && (
              <ButtonRemove onClick={removeItem} data-testid="visited-remove">
                <IconTrash />
              </ButtonRemove>
            )}
          </MediaContainer>

          <Info horizontal={horizontal}>
            <PropertyHeader>
              <NameScore>
                <NameRating>
                  <Name title={name}>{name}</Name>
                  <Stars color="grey2" stars={stars} size={12} />
                </NameRating>
              </NameScore>
              <Location>{locationInfo}</Location>
              <ScoreReviews score={score} comments={comments} />
            </PropertyHeader>

            {isLoadingContent ? (
              <BottomPlaceholders>
                <FacilitiesPlaceholder>
                  <ContentLoader
                    height={34}
                    width={100}
                    speed={1.2}
                    backgroundColor={colors.grey4}
                    foregroundColor={colors.white}
                  >
                    <rect x="0" y="5" height="8" width="100" />
                    <rect x="0" y="20" height="8" width="100" />
                  </ContentLoader>
                </FacilitiesPlaceholder>

                <PricePlaceholder>
                  <ContentLoader
                    height={34}
                    width={100}
                    speed={1.2}
                    backgroundColor={colors.grey4}
                    foregroundColor={colors.white}
                  >
                    <rect x="50" y="5" height="8" width="50" />
                    <rect x="50" y="20" height="8" width="50" />
                  </ContentLoader>
                </PricePlaceholder>
              </BottomPlaceholders>
            ) : (
              <>
                <MobileOnlyPrice isMobileRate={isMobile} />
                <PriceWrapper>
                  {isStatic ? (
                    <Facilities data-testid="facilities-wrapper">
                      <FacilitiesList perks={perks} />
                    </Facilities>
                  ) : (
                    <>
                      {!!price && (
                        <Facilities>
                          <FacilitiesList isMobile={isMobile} perks={perks} />
                        </Facilities>
                      )}
                      <PropertyPrices
                        currency={currency}
                        nights={nights}
                        price={price}
                        priceOld={priceOld}
                        priceSubtotal={priceSubtotal}
                        prime={isPrime}
                        rooms={rooms}
                        source={source}
                        priceWithFees={priceWithFees}
                        pricePerNightWithFees={pricePerNightWithFees}
                        pricePerNightSubtotal={pricePerNightSubtotal}
                      />
                    </>
                  )}
                </PriceWrapper>
              </>
            )}
          </Info>
        </PropertyBase>
      </PropertyWrapper>
    </Link>
  );
};

Property.propTypes = {
  address: PropTypes.string.isRequired,
  board: PropTypes.string,
  boardCode: PropTypes.string,
  cancellation: PropTypes.bool,
  carousel: PropTypes.bool,
  city: PropTypes.string.isRequired,
  citySlug: PropTypes.string.isRequired,
  comments: PropTypes.number,
  country: PropTypes.string.isRequired,
  currency: PropTypes.string,
  distance: PropTypes.number,
  facilities: PropTypes.arrayOf(String).isRequired,
  handleSwipeEvent: PropTypes.func,
  highlighted: PropTypes.bool,
  id: PropTypes.number,
  isInCenter: PropTypes.bool,
  isLoadingContent: PropTypes.bool,
  name: PropTypes.string.isRequired,
  nights: PropTypes.number,
  neighborhood: PropTypes.string,
  parentAction: PropTypes.func,
  photos: PropTypes.arrayOf(String),
  position: PropTypes.number,
  price: PropTypes.number,
  priceOld: PropTypes.number,
  priceWithFees: PropTypes.number,
  pricePerNightWithFees: PropTypes.number,
  pricePerNightSubtotal: PropTypes.number,
  isPrime: PropTypes.bool,
  isMobile: PropTypes.bool,
  stars: PropTypes.number,
  rooms: PropTypes.arrayOf(PropTypes.object),
  score: PropTypes.number,
  slug: PropTypes.string.isRequired,
  source: PropTypes.oneOf([
    'default',
    'visited',
    'hotelsSeen',
    'recommended',
    'related',
    'map',
    'results',
    'newYearDeals',
    'springSale',
  ]),
  subtotal: PropTypes.number,
  url: PropTypes.string,
  wide: PropTypes.bool,
  horizontal: PropTypes.bool,
};

export default Property;
