import React from "react";

import { SeeOtherDates, TooLate } from "./styles";
import { FormattedMessage } from "react-intl";
import messages from 'src/comps/Property/messages';
import IconFire from 'client/icons/Fire';

export function TooLatePrice() {
  return (
    <TooLate>
      <IconFire size={20} />
      <FormattedMessage {...messages.tooLate} tagName="span" />

      <SeeOtherDates>
        <FormattedMessage {...messages.seeOtherDates} />
      </SeeOtherDates>
    </TooLate>
  );
}

