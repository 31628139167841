import React from 'react';
import { useIntl } from 'react-intl';
import IconClose from 'client/icons/Close';

import { NotAvailableMessage, TooLate } from './styles';

export function NotAvailable() {
  const intl = useIntl();
  return (
    <TooLate>
      <NotAvailableMessage>
        <IconClose size={20} />
        {intl.formatMessage({ id: 'global.not-available-for-these-dates' })}
      </NotAvailableMessage>
    </TooLate>
  );
}
