import styled from 'styled-components';
import { colors } from 'client/styles';

const sizes = {
  xs: { icon: '18px', text: '12px' },
  s: { icon: '20px', text: '14px' },
  m: { icon: '20px', text: '14px' },
};

export const FacilityBase = styled.div`
  display: flex;
  line-height: 22px;

  svg {
    fill: ${props => colors[props.color]};
    width: ${props => sizes[props.size].icon};
    height: ${props => sizes[props.size].icon};
    flex: 0 0 auto;
  }

  p {
    width: 100%;
    color: ${props => colors[props.color]};
    display: block;
    font-size: ${props => sizes[props.size].text || '14px'};
    line-height: 20px;
    margin-left: 8px;

    text-transform: lowercase;

    &::first-letter {
      text-transform: uppercase;
    }
  }
`;
