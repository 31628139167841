import React from 'react';
import ContentLoader from 'react-content-loader';
import { DiscountTagLoaderBase, inlineStyles } from './styles';

const DiscountLoader = () => {
  return (
    <DiscountTagLoaderBase data-testid="discount-loader">
      <ContentLoader
        speed={1.2}
        width={55}
        height={32}
        backgroundColor={inlineStyles.primary}
        foregroundColor={inlineStyles.secondary}
      >
        <rect x="0" y="0" rx="5" ry="5" width="55" height="32" />
      </ContentLoader>
    </DiscountTagLoaderBase>
  );
};

export default DiscountLoader;
