import React from 'react';
import PropTypes from 'prop-types';

import dynamic from 'next/dynamic';

const Accessible = dynamic(() => import('./Accessible'));
const AirConditioner = dynamic(() => import('./AirConditioner'));
const AirportShuttle = dynamic(() => import('./AirportShuttle'));
const BananaBoating = dynamic(() => import('./BananaBoating'));
const Bar = dynamic(() => import('./Bar'));
const Bathroom = dynamic(() => import('./Bathroom'));
const Bathtub = dynamic(() => import('./Bathtub'));
const BedDouble = dynamic(() => import('../beds/Double'));
const BedSingle = dynamic(() => import('../beds/Single'));
const Breakfast = dynamic(() => import('./Breakfast'));
const CarPark = dynamic(() => import('./CarPark'));
const CarpetedFloors = dynamic(() => import('./CarpetedFloors'));
const Casino = dynamic(() => import('./Casino'));
const Cloakroom = dynamic(() => import('./Cloakroom'));
const Cooker = dynamic(() => import('./Cooker'));
const CurrencyExchange = dynamic(() => import('./CurrencyExchange'));
const DirectDialPhone = dynamic(() => import('./DirectDialPhone'));
const Diving = dynamic(() => import('./Diving'));
const Done = dynamic(() => import('../Done'));
const FirePlace = dynamic(() => import('./Fireplace'));
const Fridge = dynamic(() => import('./Fridge'));
const GameRoom = dynamic(() => import('./GameRoom'));
const Garden = dynamic(() => import('./Garden'));
const Gym = dynamic(() => import('./Gym'));
const HairdressingSalon = dynamic(() => import('./HairdressingSalon'));
const Hairdryer = dynamic(() => import('./Hairdryer'));
const Heating = dynamic(() => import('./Heating'));
const Internet = dynamic(() => import('./Internet'));
const IroningSet = dynamic(() => import('./IroningSet'));
const JetSki = dynamic(() => import('./JetSki'));
const KeyCollection = dynamic(() => import('./KeyCollection'));
const Kitchen = dynamic(() => import('./Kitchen'));
const LiftAccess = dynamic(() => import('./LiftAccess'));
const Microwave = dynamic(() => import('./Microwave'));
const Minibar = dynamic(() => import('./Minibar'));
const MotorBoard = dynamic(() => import('./MotorBoard'));
const Newspaper = dynamic(() => import('./Newspaper'));
const NightClub = dynamic(() => import('./NightClub'));
const Radio = dynamic(() => import('./Radio'));
const Receptionist = dynamic(() => import('./Receptionist'));
const Restaurant = dynamic(() => import('./Restaurant'));
const SafeBox = dynamic(() => import('./SafeBox'));
const Shield = dynamic(() => import('../Shield'));
const Shop = dynamic(() => import('./Shop'));
const Shower = dynamic(() => import('./Shower'));
const SmokeDetector = dynamic(() => import('./SmokeDetector'));
const Spa = dynamic(() => import('./Spa'));
const SwimmingPool = dynamic(() => import('./SwimmingPool'));
const Television = dynamic(() => import('./Television'));
const Terrace = dynamic(() => import('./Terrace'));
const Theatre = dynamic(() => import('./Theatre'));
const WashingMachine = dynamic(() => import('./WashingMachine'));
const WaterSkiing = dynamic(() => import('./WaterSkiing'));
const Wifi = dynamic(() => import('./Wifi'));
const WindSurfing = dynamic(() => import('./Windsurfing'));

const IconFacility = ({ name }) => {
  switch (name) {
    /* featured */
    case 'air-conditioner':
      return <AirConditioner />;
    case 'airplane':
    case 'airport-shuttle':
      return <AirportShuttle />;
    case 'parking':
    case 'garage':
      return <CarPark />;
    case 'gym':
      return <Gym />;
    case 'receptionist':
    case '24-hour-reception':
      return <Receptionist />;
    case 'safe':
      return <SafeBox />;
    case 'spa':
      return <Spa />;
    case 'pool':
    case 'outdoor':
    case 'indoor':
    case 'private-pool':
      return <SwimmingPool />;
    case 'wheelchair':
      return <Accessible />;
    case 'wifi-alt':
    case 'wifi':
    case 'wi-fi':
      return <Wifi />;

    /* common */
    case 'air-conditioning-in-public-areas':
    case 'centrally-regulated-air-conditioning':
    case 'individually-adjustable-air-conditioning':
      return <AirConditioner />;
    case 'banana-boating':
      return <BananaBoating />;
    case 'bar':
    case 'pub':
      return <Bar />;
    case 'bathroom':
    case 'private-external-bathroom':
      return <Bathroom />;
    case 'bathtub':
      return <Bathtub />;
    case 'breakfast':
      return <Breakfast />;
    case 'carpeted-floors':
      return <CarpetedFloors />;
    case 'casino':
      return <Casino />;
    case 'central-heating':
    case 'individually-adjustable-heating':
      return <Heating />;
    case 'hanger':
    case 'cloakroom':
      return <Cloakroom />;
    case 'cooker':
      return <Cooker />;
    case 'currency-exchange-facilities':
      return <CurrencyExchange />;
    case 'direct-dial-telephone':
      return <DirectDialPhone />;
    case 'diving':
      return <Diving />;
    case 'fireplace':
      return <FirePlace />;
    case 'fridge':
    case 'mini-fridge':
      return <Fridge />;
    case 'game-room':
      return <GameRoom />;
    case 'garden':
      return <Garden />;
    case 'hairdressing-salon':
      return <HairdressingSalon />;
    case 'hairdryer':
      return <Hairdryer />;
    case 'hotel-safe':
      return <Shield />;
    case 'internet':
      return <Internet />;
    case 'ironing-set':
      return <IroningSet />;
    case 'jet-ski':
      return <JetSki />;
    case 'key-collection':
      return <KeyCollection />;
    case 'kitchenette':
      return <Kitchen />;
    case 'lift-access':
      return <LiftAccess />;
    case 'microwave':
      return <Microwave />;
    case 'minibar':
      return <Minibar />;
    case 'motor-board-ride':
      return <MotorBoard />;
    case 'newspaper-stand':
      return <Newspaper />;
    case 'nightclub':
      return <NightClub />;
    case 'radio':
      return <Radio />;
    case 'restaurant':
    case 'kitchen-utensils-plates-cups':
      return <Restaurant />;
    case 'shop':
      return <Shop />;
    case 'shower':
      return <Shower />;
    case 'smoke-detector':
      return <SmokeDetector />;
    case 'terrace':
      return <Terrace />;
    case 'theatre-auditorium':
      return <Theatre />;
    case 'tv':
    case 'tv-lounge':
      return <Television />;
    case 'launderette':
    case 'laundry':
    case 'washing-machine':
    case 'laundry-service':
      return <WashingMachine />;
    case 'waterskiing':
      return <WaterSkiing />;
    case 'windsurfing':
      return <WindSurfing />;

    /* beds */
    case 'single-bed-90-130-width':
      return <BedSingle />;
    case 'double-bed-131-150-width':
    case 'king-size-bed-150-183-width':
      return <BedDouble />;

    default:
      return <Done />;
  }
};

IconFacility.propTypes = {
  name: PropTypes.string.isRequired,
};

export default IconFacility;
