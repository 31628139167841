import { colors } from "client/styles";
import styled from "styled-components";

export const NotAvailableMessage = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 140%;
  color: ${props => props.theme.colors.failure};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;

  & > svg{
    margin: 0;
  }
`;

export const TooLate = styled.div`
  color: ${colors.red2};
  font-size: 14px;
  font-weight: 700;
  width: 100%;

  svg {
    vertical-align: top;
    fill: ${colors.red2};
    margin-right: 8px;
    font-weight: 700;
  }
`;
