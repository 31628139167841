import styled from 'styled-components';

import { PricePerNightWrapper } from 'src/comps/Atom/PriceParts/PricePerNight/styles';

export const PropertyPricesBase = styled.div`
  text-align: right;
  width: 100%; 
`;

export const SubPrice = styled.span`
  color: ${props => props.theme.colors.grey3};
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
`;

export const SubPricePerNight = styled(SubPrice)`
  font-weight: 700;
`;


export const PricesWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0;
    flex-wrap: wrap;

    ${PricePerNightWrapper}{
      font-size: 12px;
      font-weight: 700;
      line-height: 140%;
    }    
`;

export const VATIncluded = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  margin-top: 1px;
  color: ${({ theme }) => theme.colors.grey3};
  white-space: nowrap;
`;
