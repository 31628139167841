import { MAX_FACILITIES } from './constants';
import { BOARD_CODE_ROOM_ONLY } from 'client/components/Rooms/constants';

export function getPerks(board, boardCode, cancellation, facilities = []) {
  const perks =
    facilities.length > MAX_FACILITIES ? facilities.slice(0, MAX_FACILITIES) : facilities;

  if (board && boardCode !== BOARD_CODE_ROOM_ONLY) {
    perks[1] = { name: board, icon: 'board' };
  }

  if (cancellation) {
    perks[2] = { name: 'freeCancellation', icon: 'checked' };
  }

  return perks;
}
