import styled from "styled-components";

import { Paragraph } from 'src/styles/typography/typography';

interface Props {
  toScroll: boolean;
}

export const ScoreReviewsBase = styled.div`
  grid-area: scoreReviews;
  display: inline-flex;
  align-items: center;
  cursor: ${(props : Props) => props.toScroll ? 'pointer' : ''}
`;

export const Category = styled.span`
  color: ${props => props.color};
  font-size: 16px;
  font-weight: 700;
  text-transform: capitalize;
  margin: 0 10px;
`;

export const Comments = styled(Paragraph)`
  text-transform: lowercase;
`;
