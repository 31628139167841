import styled from 'styled-components';
import { OldPriceWrapper } from '../../../Atom/PriceParts/OldPrice/styles';
import { CurrentPriceWrapper } from '../../../Atom/PriceParts/CurrentPrice/styles';

export const PropertyPricesBase = styled.div`
  text-align: right;
  width: 100%;
`;

export const SubPrice = styled.span`
  color: ${props => props.theme.colors.grey3};
  display: block;
  font-size: 12px;
  line-height: 17px;
`;

export const PricesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  flex-wrap: wrap;
  ${OldPriceWrapper}, ${CurrentPriceWrapper} {
    font-weight: 500;
    font-size: 16px;
    line-height: 14px;
    color: ${props => props.theme.colors.grey3};
    span {
      margin-left: 4px;
      text-transform: lowercase;
    }
  }
`;
