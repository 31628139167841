import Price from "client/components/Price";
import React from "react";
import { CurrentPriceWrapper } from "./styles";

interface ICurrentPrice {
  readonly amount: number;
  readonly currency: string;
  readonly isPrime?: boolean;
}

export function getPriceLength(amount: number) {
  return String(Math.round(amount * 1000) / 1000).length;
}

export function CurrentPrice({ amount, currency, isPrime = false }: ICurrentPrice) {
  return (
    <CurrentPriceWrapper data-testid="price-current" prime={isPrime} priceLength={getPriceLength(amount)}>
      <Price
        amount={Math.round(amount)}
        currency={currency}
      />
    </CurrentPriceWrapper>
  );
}
