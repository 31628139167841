import React, { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Price from "client/components/Price";
import messages from 'src/comps/Property/messages';
import { PricesWrapper, PropertyPricesBase, SubPrice, SubPricePerNight } from "./styles";
import { OldPrice } from "src/comps/Atom/PriceParts/OldPrice";
import { CurrentPrice } from "src/comps/Atom/PriceParts/CurrentPrice";
import { IPriceBase, PriceType } from "src/types/prices";

export interface IPriceAllIn extends IPriceBase {
  readonly priceType: PriceType.AllIn;
  readonly priceWithFees: number;
  readonly pricePerNightWithFees?: number;
  readonly pricePerNightSubtotal?: number;
  readonly source: string;
  readonly nights: number;
}

const AllIn: FC<IPriceAllIn> = ({ priceOld, price, currency, priceWithFees, pricePerNightWithFees, pricePerNightSubtotal, prime, nights }) => {
  const intl = useIntl();

  const valueNights = { nights };
  const pricePerNight = pricePerNightSubtotal ?? 0;
  const valuePriceNight = {
    price: (
      <Price amount={Math.round(pricePerNightWithFees ?? pricePerNight)} currency={currency} />
    ),
  };

  return (
    <PropertyPricesBase>
      <PricesWrapper>
        {priceOld > price && (
          <OldPrice amount={priceOld} currency={currency} />
        )}

        <CurrentPrice
          amount={Math.round(priceWithFees)}
          currency={currency}
          isPrime={prime}
        />

        <>
          <SubPricePerNight data-testid="sub-price-per-night">
            {intl.formatMessage({ id: 'global.price-night' }, valuePriceNight)}
          </SubPricePerNight>
          <SubPrice data-testid="price-nights">
            <FormattedMessage {...messages.nights} values={valueNights} />
          </SubPrice>
        </>

        <SubPrice data-testid="vat-included">
          <FormattedMessage id='net.price-total-taxes-included' />
        </SubPrice>
      </PricesWrapper>
    </PropertyPricesBase>
  );
}

export default AllIn;
